import React from 'react'
import Navbar from '../components/Navbar'
import { Footer } from '../components/Footer'
import HeroImage from '../components/HeroImage'
import PricingCards from '../components/Pricing'

export const Pricing = () => {
  return (
    <div>
      <Navbar />
      <HeroImage heading='MAIN MENU' text='Choose your food.'/>
      <PricingCards/> 
      <Footer />
      
    </div>
  )
}

export default Pricing
