import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './routes/Home';
import Pricing from './routes/Pricing'; // Add this import for the Pricing component

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/pricing" element={<Pricing />} /> {/* Use the correct path */}
    </Routes>
  );
}

export default App;


