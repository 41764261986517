import React from 'react';
import './VideoStyles.css';
import { Link } from 'react-router-dom';
import spaceVideo from '../assets/space.mp4';

const Video = () => {
  return (
    <div className='hero'>
      <video autoPlay loop muted id='video'>
        <source src={spaceVideo} type='video/mp4' />
      </video>
      <div className='content'>
        <p>Asian Foods,Indian Food,and Shan Traditional Foods.</p>
      <div>
        <Link to='/training' className='btn'>Visit</Link>
        <Link to='/contact' className='btn btn-light'>Buy</Link>
      </div>
    </div>
    </div>
  );
};

export default Video;
