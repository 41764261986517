import React from 'react';
import { Link } from 'react-router-dom'; // Add this line
import './PricingStyles.css';

const Pricing = () => {
  return (
    <div className='pricing'>
      <div className='card-container'>
        <div className='card'>
          <h3>-Shan Noddle-</h3>
          <span className='bar'></span>
          <p className='btc'>3000MMK</p>
          <p>-Chicken-</p>
          <p>-Pork-</p>
          <p>-Normal / Big-</p>
          <button className='button'>
            <Link to='/contact'>Book</Link>
          </button>
        </div>
        <div className='card'>
          <h3>-Tofu Nway-</h3>
          <span className='bar'></span>
          <p className='btc'>3500MMK</p>
          <p>-Chicken-</p>
          <p>-Prok-</p>
          <p>-Normal /San See-</p>
          <button className='button'>
            <Link to='/contact'>Book</Link>
          </button>
        </div>
        <div className='card'>
          <h3>-Shan Htamin Chin-</h3>
          <span className='bar'></span>
          <p className='btc'>2800MMK</p>
          <p>-Chicken-</p>
          <p>-Pork-</p>
          <p>-Half Boiled Eggs-</p>
          <button className='button'>
            <Link to='/contact'>Book</Link>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Pricing;
