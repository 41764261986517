import React from 'react'
import './FooterStyles.css'
import { FaFacebook,FaLinkedin,FaMailBulk,FaPhone,FaSearchLocation,FaTwitter } from 'react-icons/fa'

export const Footer = () => {
  return (
    <div className='footer'>
        <div className='footer-container'>
            <div className='left'>
                <div className='location'>
                    <FaSearchLocation size={20} style={{color : '#ffffff' , marginRight : '2rem'}}/>
                    <div>
                        <p>NO 14, BO CHOKE Houston.</p>
                        <h4>Pyay Road, Tx.</h4>
                    </div>
                </div>
                <div className='phone'>
                    <h4><FaPhone size={20} style={{color : '#ffffff' , marginRight : '2rem'}}/>+95955243415</h4>
                </div>
                <div className='email'>
                    <h4><FaMailBulk size={20} style={{color : '#ffffff' , marginRight : '2rem'}}/>sheinnminn515@gmail.com</h4>
                </div>
            </div>
            <div className='right'>
                <h4>About the Restaurant</h4>
                    <p>Organizer, Craig Stephen, opened the first ‘Organization Name’ Restaurant in Los Alamitos, California, on September 27, 1977. Today, there are 37 areas all through Arizona, California, Illinois, Louisiana, Nevada, Oregon, Tennessee, and Washington.
                    ‘Organization Name’ Restaurants are well known with a substantial gathering of people, including families, kids, seniors, and business experts. Our benevolent condition is perfect for praising unique events, facilitating a business lunch, or assembling for a flavorful dinner with loved ones.Open day by day for lunch and dinner,’ Company Name’ offers a choice of naturally arranged things utilizing just the best fixings accessible. Top picks incorporate Certified Angus Beef®, crisp fish, rotisserie chicken, and infant back pork ribs.
                    New prepared pot pie, strength plates of mixed greens, wood-let go pizzas, pasta, sandwiches, burgers, and more.’Company Name’s heated merchandise and treats, including our Six-Layer Chocolate Motherlode Cake, Scratch Carrot Cake, and delectably rich cream cheddar pies are prevalent top choices with our visitors.
                    </p>
                    <div className='social'>
                        <FaFacebook size={30} style={{color : '#ffffff' , marginRight : '1rem'}}/>
                        <FaTwitter size={30} style={{color : '#ffffff' , marginRight : '1rem'}}/>
                        <FaLinkedin size={30} style={{color : '#ffffff' , marginRight : '1rem'}}/>
                    </div>
            </div>
        </div>
    </div>

  )
}
